<template>
    <div class="avatar" :class="size">
        <div v-if="mimetype == 'application/pdf'" class="text-center border border-2" :class="typeClass">
            <span class="fe h1 text-muted fe-file-text"></span>
            <div class="h4 text-muted">pdf</div>
        </div>
        <div v-else-if="mimetype == 'application/msword'" class="text-center border border-2" :class="typeClass">
            <span class="fe h1 text-muted fe-file-text"></span>
            <div class="h4 text-muted">doc</div>
        </div>
        <div v-else-if="mimetype == 'application/zip'" class="text-center border border-2" :class="typeClass">
            <span class="fe h1 text-muted fe-archive"></span>
            <div class="h4 text-muted">zip</div>
        </div>
        <img v-else-if="src" :src="src" :width="width" class="avatar-img" :class="typeClass">
        <div v-else class="text-center border border-3" :class="typeClass">
            <span class="fe h1 text-muted fe-image"></span>
        </div>
    </div>
</template>

<script>
export default {
    props: ['src', 'width', 'type', 'size', 'mimetype'],
    computed: {
        typeClass() {
            let maps = {
                'rounded': 'rounded',
                'circle': 'rounded-circle',
            }
            return maps[this.type] || '';
        }
    }
}

</script>

<style>

</style>
