<template>
    <div class="row">
        <div class="col-12">

            <!-- <pre>
                {{images}}
            </pre> -->

            <uploader type="application/pdf,.doc,.zip,image/*" :params="fileData"></uploader>

            <div class="card">
                <div class="card-header">
                    <div class="row">
                        <div class="col">
                            <!-- Form -->
                            <div class="input-group input-group-flush input-group-merge">
                                <input
                                    type="search"
                                    v-model="query"
                                    class="form-control form-control-prepended search"
                                    :placeholder="$t('Search')"
                                />
                                <div class="input-group-prepend">
                                    <div class="input-group-text">
                                        <span class="fe fe-x text-muted" v-if="query" @click="query=null"></span>
                                        <span class="fe fe-search text-muted" v-else></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-auto">
                            <div class="dropdown">
                                <b-dropdown variant="white" right :text="$t('Tags')">
                                    <div class="p-2">
                                        <b-form-checkbox
                                            v-for="(tag, index) in tags"
                                            :key="index"
                                            v-model="tagsQuery"
                                            :value="tag"
                                            class="mb-3 pl-5"
                                        >{{ $t(tag) }}</b-form-checkbox>
                                    </div>
                                </b-dropdown>
                            </div>
                        </div>
                    </div>
                    <!-- / .row -->
                </div>
                <div class="card-body" v-if="!areFilesLoading && images.length==0">
                    <div class="row">
                        <div class="col-12 text-center">{{$t('Nothing yet')}}</div>
                    </div>
                </div>
                <div class="card-body" v-if="areFilesLoading">
                    <div class="row align-items-center active">
                        <div class="spinner-border text-primary" role="status">
                            <span class="sr-only">{{$t('Loading...')}}</span>
                        </div>
                    </div>
                </div>
                <div class="card-body" v-else>
                    <ul class="list-group list-group-lg list-group-flush list my-n4">
                        <li class="list-group-item px-0" v-for="(image, index) in images" :key="index">
                            <div class="row align-items-center">
                                <div class="col-auto pointer" @click="openModal({id:'mediaViewModal', model:image})">                                    
                                    <file-preview :src="image.url" :mimetype="image.mimetype" type="rounded"></file-preview>
                                </div>
                                <div class="col ml-n2">
                                    <h4 class="card-title mb-1 name pointer" @click="openModal({id:'mediaViewModal', model:image})">{{ image.label }}</h4>
                                    <p
                                        class="card-text small text-muted mb-1"
                                    >{{ size(image.size) }}, {{ image.mimetype }}</p>
                                    <p class="card-text small text-muted">
                                        <!-- <avatar
                                            type="circle"
                                            v-if="image.user"
                                            :src="image.user.avatar.preview"
                                            size="avatar-xs"
                                        ></avatar> -->
                                        <span
                                            v-for="(tag, tagIndex) in image.tags"
                                            :key="tagIndex"
                                            class="badge badge badge-soft-info ml-1"
                                        >{{ tag }}</span>
                                    </p>
                                </div>
                                <div class="col-auto">
                                    <p class="card-text small text-muted">{{ image.createdAt | moment("calendar") }}</p>
                                </div>
                                <div class="col-auto">
                                    <button
                                        class="btn btn-white ml-2"
                                        @click="openModal({id:'mediaViewModal', model:image})"
                                    >
                                        <i class="fe fe-eye"></i>
                                    </button>
                                    <button
                                        class="btn btn-white ml-2"
                                        @click="openModal({id:'confirmationModal', model:image})"
                                    >
                                        <i class="fe fe-trash"></i>
                                    </button>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <confirmation-modal id="confirmationModal" :item="model" :confirmAction="deleteItem"></confirmation-modal>
        <media-view-modal id="mediaViewModal" :item="model"></media-view-modal>
    </div>
</template>

<script>
import debounce from 'lodash/debounce';
import Avatar from '@/components/Avatar.vue'
import { mapActions, mapGetters, mapState } from 'vuex';
import { modalMixins } from '@/mixins/modal.js'
import Uploader from '@/components/Uploader.vue'
import PrettyBytes from '@/filters/pretty-bytes.js';
import FilePreview from '@/components/FilePreview.vue'
import CameraModal from '@/components/CameraModal.vue';
import ConfirmationModal from '@/components/ConfirmationModal.vue';
import MediaViewModal from '@/components/MediaViewModal.vue'

export default {
    props: ['id'],
    mixins: [modalMixins],
    components: {
        Avatar,
        Uploader,
        FilePreview,
        CameraModal,
        MediaViewModal,
        ConfirmationModal
    },
    data() {
        return {
            query: null,
            tags: ['Documents', 'Images', 'Archive'],
            tagsQuery: ['', 'Documents', 'Images', 'Archive'],
            model: {},
            fileData: {
                tags: ['Storage']
            },
            params: {
                query: {
                    $limit: 1000,
                    $sort: {
                        createdAt: -1
                    },
                }
            }
        }
    },
    computed: {
        ...mapState('auth', {
            userPayload: 'payload',
        }),
        ...mapState('media', { areFilesLoading: 'isFindPending' }),
        ...mapGetters('media', {
            findMediaInStore: 'find'
        }),
        ...mapGetters('users', {
            user: 'get'
        }),
        images() {
            //this.params.query.userId = this.user
            let images = this.findMediaInStore(this.params).data;
            images.map(image => {
                image.user = this.user(image.userId);
            })
            return images;
        },
    },
    created() {
        this.params.query.userId = this.userPayload.userId;
        this.find(this.params);
        this.$root.$on('bv::hide::modal', (modal) => {
            this.hideForm = false;
        });

    },
    watch: {
        query: debounce(function (newVal) {
            if (newVal) {
                this.params.query.$or = [
                    { label: { $regex: newVal, $options: "i" } },
                ];
            } else {
                delete this.params.query.$or;
            }

            this.find();
        }, 400),
        tagsQuery: debounce(function (newVal) {
            this.params.query.tags = { $in: newVal };
            this.find();
        }, 400),
    },
    methods: {
        ...mapActions('media', ['find', 'remove', 'create']),
        ...mapActions('users', { fetchUsers: 'find' }),

        size(value) {
            return PrettyBytes(Number(value));
        },
        use(image) {
            this.$root.$emit('bv::hide::modal', this.id);
            this.action(image);
        },
        save() {
            this.errors = null;
            this.hideForm = true;
        },
        deleteItem() {
            this.remove(this.model._id).then(result => {
                this.$toasted.global.success({
                    message: this.$t('Item has been deleted')
                });
            });
        }

    },


}
</script>

<style lang="scss">
.pointer {
    cursor: pointer;
}

</style>