<template>
    <div>
        <nav
            class="navbar navbar-expand-md navbar-light top-navbar"
            id="topbar-content"
        >
            <div class="container-fluid">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="#" class="navbar-brand">Dashboard</a></li>
                        <li class="breadcrumb-item active d-flex justify-content-center align-items-center " aria-current="page">Media</li>
                    </ol>
                </nav>
            </div>
        </nav>

        <div class="container">
            
          <div class="header">
            <div class="header-body">
              <div class="row align-items-center">
                <div class="col">
                  <h6 class="header-pretitle">
                    Stored photos
                  </h6>
                  <h1 class="header-title">
                    Media
                  </h1>
                </div>
                <div class="col-auto">

                </div>
              </div>
              
            </div>
          </div>

          <storage></storage>

        </div>

    </div>
</template>

<script>
import Storage from '@/Pages/Storage';

export default {
    components: {
        Storage
    }
}
</script>