<template>
    <modal
        :saveAction="save"
        :title="item.label"
        :id="id"
        :actionButtons="false"
        size="xl"
        :closeButton="true"
        scrollable
        lazy
    >
        <!-- {{ item.user }} -->
        <div class="row" v-if="item.label">
            <div class="col-8 text-center">
                <img :src="item.url" class="img-fluid rounded" />
            </div>
            <div class="col">
                <div class="card">
                    <div class="card-body">
                        <div class="row align-items-center">
                            <div class="col">
                                <h5 class="mb-0">{{ item.label}}</h5>
                            </div>
                        </div>

                        <hr />
                        <div class="row align-items-center">
                            <div class="col">
                                <h5 class="mb-0">Size</h5>
                            </div>
                            <div class="col-auto">{{ size }}</div>
                        </div>
                        <hr v-if="item.user" />
                        <div class="row align-items-center" v-if="item.user">
                            <div class="col">
                                <h5 class="mb-0">Author</h5>
                            </div>
                            <div class="col-auto">
                                <avatar
                                    type="circle"
                                    v-if="item.user.avatar"
                                    :src="item.user.avatar.preview"
                                    size="avatar-xs"
                                ></avatar>
                                {{ item.user.firstName }}
                            </div>
                        </div>
                        <hr />

                        <div class="row align-items-center">
                            <div class="col">
                                <h5 class="mb-0">Date</h5>
                            </div>
                            <div class="col-auto">{{ item.createdAt | moment('calendar') }}</div>
                        </div>
                        <hr />
                        <div class="row align-items-center">
                            <div class="col">
                                <h5 class="mb-0">Tags</h5>
                            </div>
                            <div class="col-auto">
                                <span
                                    v-for="(tag, tagIndex) in item.tags"
                                    :key="tagIndex"
                                    class="badge badge badge-soft-info ml-1"
                                >{{ tag }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <button class="btn btn-secondary" @click="download()">
                    <i class="fe fe-download"></i>
                </button>
                <button class="btn btn-danger ml-2" @click="deleteFile()">
                    <i class="fe fe-trash"></i>
                </button>
            </div>
        </div>
    </modal>
</template>

<script>
import Modal from "@/components/Modal.vue";
import Avatar from '@/components/Avatar.vue'
import PrettyBytes from '@/filters/pretty-bytes.js';
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
    props: ['id', 'item'],
    components: {
        Modal,
        Avatar
    },
    data() {
        return {
            title: 'View media',
        }
    },
    computed: {
        size() {
            const value = Number(this.item.size);
            return PrettyBytes(value);
        }
    },
    mounted() {
        this.title = this.item.label;
    },
    methods: {
        ...mapActions('media', ['remove']),
        download() {
            window.open(this.item.url);
        },
        deleteFile() {
            this.$root.$emit('bv::hide::modal', this.id);
            this.remove(this.item._id).then(result => {
                this.$toasted.global.success({
                    message: this.$t('Item has been deleted')
                });
            });
        },
        save() {

        }
    }
}
</script>

<style>

</style>
